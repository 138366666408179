<template>
  <div>
    <el-radio-group v-model="courseInfo.useType" v-if="pageType == 'file'">
      <el-radio :label="2">指定范围</el-radio>
      <el-radio :label="1">全部</el-radio>

    </el-radio-group>
    <div class="radius" v-if="(pageType != 'file' && courseInfo.useType != 2)||(pageType == 'file'&&  courseInfo.useType == 2)">
      <div class="radiu-top">
        <div>已选员工（{{ courseInfo.useUserIds && courseInfo.useUserIds.length }}）</div>
      </div>
      <div class="tag-content">
        <el-tag
          class="tag-item"
          effect="plain"
          v-for="(tag, index) in courseInfo.useUserIds"
          :key="tag.dingId"
          closable
          :disable-transitions="false"
          @close="_closeEmployee(tag, index)"
        >
          {{ tag.name }}
        </el-tag>
        <el-button class="button-new-tag" type="primary" @click="_addEmployee"
          >+ 员工</el-button
        >
      </div>
      <div class="radiu-top">
        <div>已选部门（{{ courseInfo.useDeptIds && courseInfo.useDeptIds.length }}）</div>
      </div>
      <div class="tag-content">
        <el-tag
          class="tag-item"
          effect="plain"
          v-for="(tag, index) in courseInfo.useDeptIds"
          :key="tag.dingId"
          closable
          :disable-transitions="false"
          @close="_closeDepartment(tag, index)"
        >
          {{ tag.name }}
        </el-tag>
        <el-button class="button-new-tag" type="primary" @click="_addDepartment"
          >+ 部门</el-button
        >
      </div>
    </div>
    <SelectDepartment
      v-if="departmentShow"
      :choosedKey="courseInfo.useDeptIds"
      :departmentShow="departmentShow"
      @submitHandle="_submitDepartment"
      title="选择部门"
    />
    <!-- 选择人员 -->
    <SelectPersonnel
      v-if="personnelShow"
      :choosedKey="courseInfo.useUserIds"
      :personnelShow="personnelShow"
      @submitHandle="_submitPersonnel"
      title="选择人员"
    />
  </div>
</template>

<script>
import SelectDepartment from '@/components/selectDepartment/index.vue'
import SelectPersonnel from '@/components/selectPersonnel/index.vue'
export default {
  components: {
    SelectDepartment,
    SelectPersonnel
  },
  props: {
    pageType: {
      default: '',
      type: String
    },
    courseInfo: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      departmentShow: false, // 部门
      personnelShow: false // 人员
    }
  },
  methods: {
    _addDepartment () {
      // 显示部门分组
      this.departmentShow = true
    },
    _addEmployee () {
      // 显示员工分组
      this.personnelShow = true
    },
    _submitPersonnel (arr) {
      // 人员提交
      this.courseInfo.useUserIds = arr
      this.personnelShow = false
    },
    _submitDepartment (arr) {
      // 部门提交
      this.courseInfo.useDeptIds = arr
      this.departmentShow = false
    },
    _closeEmployee (item, index) {
      // 人员tag删除
      this.courseInfo.useUserIds.splice(index, 1)
    },
    _closeDepartment (item, index) {
      // 部门tag删除
      this.courseInfo.useDeptIds.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.radius {
  width: 93%;
  background: #f6f7f9;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  margin-top: 8px;
  .radiu-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 14px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}
.tag-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button-new-tag {
  padding-top: 0;
  padding-bottom: 0;
  width: 78px;
  height: 28px !important;
  line-height: 12px !important;
  text-align: center;
  margin-bottom: 10px;
}
.tag-item {
  margin-right: 10px;
  // width: 78px;
  height: 28px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
}
.add-group {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  .add-group-btn {
    width: 102px;

    border: 1px solid #0089ff;
    font-size: 14px;
    color: #0089ff;
  }
}
.add-group-name {
  margin-bottom: 10px;
}
/deep/.el-dialog__body {
  max-height: 420px;
  overflow: auto;
}
</style>
